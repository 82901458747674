import React from 'react'
// import Img from 'react-fix-image-orientation'

const StyledImage = (props) =>
  <img
    {...props}
    style={{
      width: '50%'
    }}
  />

export default function PhotoboothOfFriendship (props) {
  return (
    <>
      <h1>The Photobooth of Friendship</h1>

      <p>This was a virtual photo booth run via Zoom for an immersive internet party during the COVID-19 lockdown.  </p>
      <p>Users would join the Zoom room and would hear an explanation that we will take 4 photos of them and then send them a link to a form to fill out.  The form had a place for them to enter their physical address.  After the event, I mailed out 90 post cards with user's photos on them.  A sample is below.</p>
      <p>Part of the idea is to connect people to make new friends, so we also included the photos and email address of a random consenting stranger on each postcard.</p>

      <StyledImage src='/images/projects/photoboothOfFriendship/front.png' />
      <br />
      <StyledImage src='/images/projects/photoboothOfFriendship/back.png' />
    </>
  )
}
