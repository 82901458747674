import React from 'react'

import { Link as RouterLink } from 'react-router-dom'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import HomeIcon from '@material-ui/icons/Home'
import BuildIcon from '@material-ui/icons/Build'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import MailIcon from '@material-ui/icons/Mail'
import Typography from '@material-ui/core/Typography'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Hidden from '@material-ui/core/Hidden'

import Home from './pages/Home.js'
import Contact from './pages/Contact.js'
import Projects from './pages/Projects.js'
import Microgrants from './pages/Microgrants.js'

const InternalLink = React.forwardRef((props, ref) => <RouterLink innerRef={ref} {...props} />)

const drawerWidth = 240

export const menuItems = [
  {
    name: 'Home',
    path: '/',
    component: Home,
    icon: <HomeIcon />
  },
  {
    name: 'Projects',
    path: '/projects',
    component: Projects,
    icon: <BuildIcon />
  },
  // {
  //   name: 'Microgrants',
  //   path: '/microgrants',
  //   component: Microgrants,
  //   icon: <AccountBalanceIcon />
  // },
  {
    name: 'Contact',
    path: '/contact',
    component: Contact,
    icon: <MailIcon />
  }
]

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  title: {
    flexGrow: 1
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    },
    flexGrow: 0
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  toolbarStuffHolder: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}))

export function Header (props) {
  const { container } = props
  const classes = useStyles()

  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <div className={classes.toolbarStuffHolder}>
          <Typography variant='h6' className={classes.title}>
            <img className='App-logo' src='/images/emu.jpg' alt='Emu Logo' />
            Hello
          </Typography>
        </div>
      </div>
      <Divider />
      <List>
        {menuItems.map((item, index) => (
          <ListItem onClick={() => setMobileOpen(false)} button component={InternalLink} to={item.path} key={'menuitem_' + index}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.name} />
          </ListItem>
        ))}
      </List>
    </div>
  )

  return (
    <>
      <AppBar position='fixed' className={classes.appBar}>
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Hidden smUp implementation='css'>
            <Typography variant='h6' className={classes.title}>
              <img className='App-logo' src='/images/emu.jpg' alt='Emu Logo' />
              Hello
            </Typography>
          </Hidden>
        </Toolbar>
      </AppBar>

      <nav className={classes.drawer} aria-label='mailbox folders'>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation='css'>
          <Drawer
            container={container}
            variant='temporary'
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation='css'>
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant='permanent'
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </>
  )
}
