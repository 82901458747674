import React from 'react'
import './App.css'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import { Header, menuItems } from './Header.js'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { projectItems } from './pages/Projects.js'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  toolbar: theme.mixins.toolbar
}))

export default function App () {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Router>
        <Header />
        <Container>
          <div className={classes.toolbar} />
          <div>
            {menuItems.map((m, i) => <Route key={'menu_link_' + i} path={m.path} exact component={m.component} />)}
            {projectItems.map((m, i) => <Route key={'project_item_' + i} path={m.path} exact component={m.component} />)}
          </div>
          <div style={{ height: 32 }} />

        </Container>
      </Router>
    </div>
  )
}
