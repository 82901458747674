import React from 'react'
// import Img from 'react-fix-image-orientation'

const StyledImage = (props) =>
  <img
    {...props}
    style={{
      width: '50%'
    }}
  />

export default function Antenna (props) {
  return (
    <>
      <h1>Easy magnetic shipping container antenna mast</h1>

      <p>So, you wanna put up a 20ft antenna, but all you have is a shipping container and unlimited trips to the hardware store.  Here's the easiest way to do it.</p>

      <StyledImage src='/images/projects/antenna/IMG_20190824_230918.jpg' />

      <h3>Parts:</h3>
      <ul>
        <li>
          <a href='https://www.homedepot.com/p/Everbilt-95-lb-Heavy-Duty-Round-Pull-Magnets-96364/203613147?keyword=95lb+magnet&semanticToken=20310000111_20191101161554860591_5hcp+20310000111+%3E++cnn%3A%7B0%3A0%7D+cnr%3A%7B7%3A1%7D+cnd%3A%7B4%3A0%7D+cne%3A%7B8%3A0%7D+cnb%3A%7B0%3A0%7D+cns%3A%7B5%3A0%7D+cnx%3A%7B3%3A0%7D+st%3A%7B95lb+magnet%7D%3Ast+oos%3A%7B0%3A1%7D+pt%3A%7Bmagnet%7D%3Apt+dln%3A%7B562808%7D+qu%3A%7B95lb+magnet%7D%3Aqu'>6x 95 pound magnets</a>
        </li>

        <li>
          <a href='https://www.homedepot.com/p/1-1-4-in-x-10-ft-Rigid-Metal-Conduit-0544110000/202068052'>
            2x 10ft 1 1/4in rigid metal conduit
          </a>
        </li>

        <li>
          <a href='https://www.homedepot.com/p/Halex-3-ACC-Conduit-Hanger-67830/100152234'>6x pipe hangers</a>
        </li>

        <li>
          <a href='https://www.homedepot.com/p/Everbilt-1-4-in-x-1-in-Galvanized-Hex-Bolt-15-Pack-80450/203151675'>6x bolts (I think these are the right size, but can't remember exactly.  Check at the store to see if they will fit)</a>
        </li>

        <li>
          <a href='https://www.homedepot.com/p/Everbilt-1-4-in-20-Zinc-Plated-Hex-Nut-100-Pack-801730/204274089'>6x nuts</a>
        </li>
      </ul>

      <h3>Assembly</h3>

      <p>To assemble, first, mount the magnets to the pipe hangers with the extra nuts and bolts you have.  Then, stick them to the shipping container in the arrangement that will hold your pipe.  I spaced them out evenly from top to bottom, and bent the pipe hangers open so they would be ready to receive the pipe.  You can see the bent pipe hangars in the photo below (on far right of photo)</p>

      <StyledImage src='/images/projects/antenna/IMG_20190823_174735.jpg' />

      <p>Then, screw the two pipes together.  This might be kind of hard and take a lot of jiggling.  Mount your antenna at the top, and then you're ready to stand the antenna up and put it into the pipe hangars.  The pipe is super heavy so you may need help with this to avoid dropping the pipe by accident.  While one person holds the pipe in place, close the pipe hangers and bolt them shut.</p>

      <p>You're done!  That was easy, huh? Pics of this in action are below.  I also added addressable LEDs to make it awesome.  Bonus pic below is what your computer will look like after a week at burning man.  Sure glad I bought that keyboard protector!</p>

      <StyledImage src='/images/projects/antenna/IMG_20190824_230953.jpg' />
      <br />
      <StyledImage src='/images/projects/antenna/IMG_20190901_214153.jpg' />
    </>
  )
}
