import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

export default function Photoboo (props) {
  return (
    <>
      <h1>A haunted photo booth that finds ghosts</h1>
      <p>Super fun at halloween parties!  Originally created by Adonis Gaitatzis and modified to work with multiple faces for the 20mission "Ghosted, Again?!" 2019 halloween party.</p>
      <a href='https://github.com/glitch003/photoboo'>Source Code Link</a>
      <br />
      <RouterLink to='/projects/photoboo/gallery'>Photo gallery from 20mission "Ghosted, Again?!" 2019 halloween party</RouterLink>
    </>
  )
}
