import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const InternalLink = React.forwardRef((props, ref) => <RouterLink innerRef={ref} {...props} />)

const galleries = [
  {
    name: 'Propaganda Posters',
    url: '/projects/stylegan/propaganda',
    imageUrl: '/images/projects/stylegan/propaganda/example_0.png'
  },
  {
    name: 'Self Portraits',
    url: '/projects/stylegan/me',
    imageUrl: '/images/projects/stylegan/me/example_0.png'
  },
  {
    name: 'Tattoos',
    url: '/projects/stylegan/tattoos',
    imageUrl: '/images/projects/stylegan/tattoos/example_0.png'
  },
  {
    name: 'Philip DeFranco',
    url: '/projects/stylegan/phil',
    imageUrl: '/images/projects/stylegan/phil/example_0.png'
  }
]

const useStyles = makeStyles(theme => ({
  itemRoot: {
    textAlign: 'center'
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  cardContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  cardActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 16,
    marginBottom: 16
  },
  image: {
    width: '100%'
  }
}))

export default function Stylegan (props) {
  const classes = useStyles()
  return (
    <>
      <h1>Experiments with StyleGAN</h1>
      <p>StyleGAN was created by researchers at NVIDIA.  It takes images as input, and trains a GAN to generate new images that match the style of the source images.</p>
      <h3>Check out some of the galleries below to see the results of some of things I've "StyleGanned".</h3>
      <br />
      <Grid container spacing={3}>
        {
          galleries.map((gallery, i) =>
            <Grid
              key={'gallery_' + i}
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              className={classes.itemRoot}
            >
              <Card
                component={InternalLink}
                to={gallery.url}
                className={classes.card}
              >
                <CardContent className={classes.cardContent}>
                  <Typography variant='h5' component='h2'>
                    {gallery.name}
                  </Typography>
                  <img src={gallery.imageUrl} className={classes.image} />
                </CardContent>
              </Card>
            </Grid>
          )
        }
      </Grid>
    </>
  )
}
