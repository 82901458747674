import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

import PhotoGallery from "./PhotoGallery";
import Antenna from "./projects/Antenna";
import Photoboo from "./projects/Photoboo";
import FishFinder from "./projects/FishFinder";
import Stylegan from "./projects/Stylegan";
import PropagandaGallery from "./projects/PropagandaGallery";
import MeGallery from "./projects/MeGallery";
import TattooGallery from "./projects/TattooGallery";
import PhilGallery from "./projects/PhilGallery";
import ReplacedByAIGallery from "./projects/ReplacedByAIGallery";
import Gallery20191231 from "./projects/Gallery20191231";
import PhotoboothOfFriendship from "./projects/PhotoboothOfFriendship";
import ArtistBuyingOwnNFT from "./projects/ArtistBuyingOwnNFT";
import NftClub from "./projects/NftClub";
import ButtCrystals from "./projects/ButtCrystals";

const InternalLink = React.forwardRef((props, ref) => (
  <RouterLink innerRef={ref} {...props} />
));

export const projectItems = [
  // {
  //   name: 'Butt Crystals',
  //   path: '/projects/buttcrystals',
  //   component: ButtCrystals
  // },
  {
    name: "NFT Club",
    path: "/projects/nftclub",
    component: NftClub,
  },
  {
    name: "Artist buying own NFT of artist buying own NFT",
    path: "/projects/artistbuyingownnft",
    component: ArtistBuyingOwnNFT,
  },
  {
    name: 'Ghostly Photo Booth (aka "Photoboo")',
    path: "/projects/photoboo",
    component: Photoboo,
  },
  {
    name: 'Ghostly Photo Booth (aka "Photoboo") gallery',
    path: "/projects/photoboo/gallery",
    component: PhotoGallery,
  },
  {
    name: "Easy magnetic shipping container antenna mast",
    path: "/projects/antenna",
    component: Antenna,
  },
  {
    name: "Bluetooth 5 long range friend finder",
    path: "/projects/fishfinder",
    component: FishFinder,
  },
  {
    name: "StyleGAN",
    path: "/projects/stylegan",
    component: Stylegan,
  },
  {
    name: "AI Generated Propaganda Gallery",
    path: "/projects/stylegan/propaganda",
    component: PropagandaGallery,
  },
  {
    name: "AI Generated Photos of Myself",
    path: "/projects/stylegan/me",
    component: MeGallery,
  },
  {
    name: "AI Generated Tattoos",
    path: "/projects/stylegan/tattoos",
    component: TattooGallery,
  },
  {
    name: "AI Generated Tattoos",
    path: "/projects/stylegan/phil",
    component: PhilGallery,
  },
  {
    name: "You've been replaced by an AI",
    path: "/projects/replaced",
    component: ReplacedByAIGallery,
  },
  {
    name: "You've been replaced by an AI",
    path: "/a/2019-12-31",
    component: ReplacedByAIGallery,
  },
  {
    name: "",
    path: "/galleries/2019-12-31",
    component: Gallery20191231,
  },
  {
    name: "Photobooth of Friendship",
    path: "/projects/photoboothOfFriendship",
    component: PhotoboothOfFriendship,
  },
];

const projects = [
  {
    title: "Lit Protocol",
    desc: "A decentralized access control protocol",
    link: (
      <Button
        variant="contained"
        size="small"
        color="primary"
        onClick={() => (window.location = "https://litprotocol.com")}
      >
        Learn More
      </Button>
    ),
  },
  {
    title: "Galactic Tick Day",
    desc: "A science awareness holiday to celebrate our solar system's rotation around the center of the Milky Way galaxy.  Co-creator.",
    link: (
      <Button
        variant="contained"
        size="small"
        color="primary"
        onClick={() => (window.location = "http://www.galactictick.com")}
      >
        Learn More
      </Button>
    ),
  },
  {
    title: "Artist buying own NFT of artist buying own NFT",
    desc: "A self-referential video of an artist buying their own NFT",
    link: (
      <Button
        variant="contained"
        size="small"
        color="primary"
        component={InternalLink}
        to="/projects/artistbuyingownnft"
      >
        Learn More
      </Button>
    ),
  },

  {
    title: "NFT Club",
    desc: "Create links that only NFT holders can view (on ethereum)",
    link: (
      <Button
        variant="contained"
        size="small"
        color="primary"
        component={InternalLink}
        to="/projects/nftclub"
      >
        Learn More
      </Button>
    ),
  },

  {
    title: "Dev News",
    desc: "News from hacker news and reddit, by devs for devs.",
    link: (
      <Button
        variant="contained"
        size="small"
        color="primary"
        onClick={() => (window.location = "https://www.devnews.dev")}
      >
        Learn More
      </Button>
    ),
  },

  // {
  //   title: 'Butt Crystals',
  //   desc: 'Delicate crystals for your powerful anus',
  //   link: (
  //     <Button variant='contained' size='small' color='primary' component={InternalLink} to='/projects/buttcrystals'>
  //       Learn More
  //     </Button>
  //   )
  // },

  {
    title: "Easy magnetic shipping container antenna mast",
    desc: "Got a shipping container handy, and need to deploy a 20ft antenna with parts from your local Home Depot?  This project makes it super easy using the power of magnets and human ingenuity.  Originally designed for a microwave wifi link at Burning Man 2019",
    link: (
      <Button
        variant="contained"
        size="small"
        color="primary"
        component={InternalLink}
        to="/projects/antenna"
      >
        Learn More
      </Button>
    ),
  },

  {
    title: "Bluetooth 5 long range friend finder",
    desc: "When you're off the grid, but wanna know if your friends are nearby, you can use this to see if they're close.  Useful for festivals and other similar social events.  Originally designed for Burning Man 2019",
    link: (
      <Button
        variant="contained"
        size="small"
        color="primary"
        component={InternalLink}
        to="/projects/fishfinder"
      >
        Learn More
      </Button>
    ),
  },

  {
    title: "A haunted photo booth that finds ghosts",
    desc: 'Super fun at halloween parties!  Originally created by Adonis Gaitatzis and modified to work with multiple faces for the 20mission "Ghosted, Again?!" 2019 halloween party.',
    link: (
      <Button
        variant="contained"
        size="small"
        color="primary"
        component={InternalLink}
        to="/projects/photoboo"
      >
        Learn More
      </Button>
    ),
  },

  {
    title: "Experiments with StyleGAN and AI generated images",
    desc: "StyleGAN is an AI that learns the style of a set of images.  I've experimented with it quite a bit, and generated pics of myself, propaganda, and tattoo designs.",
    link: (
      <Button
        variant="contained"
        size="small"
        color="primary"
        component={InternalLink}
        to="/projects/stylegan"
      >
        Learn More
      </Button>
    ),
  },
  {
    title: "You've been replaced by an AI - A photo booth",
    desc: "Interactive art piece that invites the viewer to think about their role in our rapidly approaching automated future.  What it will be like to be replaced by an AI?",
    link: (
      <Button
        variant="contained"
        size="small"
        color="primary"
        component={InternalLink}
        to="/projects/replaced"
      >
        Learn More
      </Button>
    ),
  },
  {
    title: "Piper Wallet",
    desc: "A bitcoin and cryptocurrency paper wallet printer.  A dedicated device that securely generates and prints paper wallets.  Supports storing your keys on a USB stick, encrypting your keys, and printing to any USB printer.  Created the hardware, software, and built and sold over 500 units on my own.",
    link: (
      <Button
        variant="contained"
        size="small"
        color="primary"
        onClick={() =>
          (window.location =
            "https://web.archive.org/web/20160309032223/http://cryptographi.com/")
        }
      >
        Learn More
      </Button>
    ),
  },
  {
    title: "Photobooth of Friendship",
    desc: "A Zoom photo booth that sends users real postcards of their photos with the contact info of a stranger so they can become friends.",
    link: (
      <Button
        variant="contained"
        size="small"
        color="primary"
        component={InternalLink}
        to="/projects/photoboothOfFriendship"
      >
        Learn More
      </Button>
    ),
  },
];

const useStyles = makeStyles((theme) => ({
  itemRoot: {
    textAlign: "center",
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardContent: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  cardActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 16,
    marginBottom: 16,
  },
}));

export default function Projects(props) {
  const classes = useStyles();

  return (
    <div>
      <h1>My projects</h1>
      <Grid container spacing={3}>
        {projects.map((p, i) => (
          <Grid
            key={"project_" + i}
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            className={classes.itemRoot}
          >
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography variant="h5" component="h2">
                  {p.title}
                </Typography>
                <Typography variant="body2" component="p">
                  {p.desc}
                </Typography>
              </CardContent>
              <CardActions className={classes.cardActions}>
                {p.link}
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
