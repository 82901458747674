import React from 'react'
import Grid from '@material-ui/core/Grid'

export default function FishFinder (props) {
  return (
    <>
      <h1>Bluetooth 5 long range friend finder</h1>

      <p>When you're off the grid, but wanna know if your friends are nearby, you can use this to see if they're close.  Useful for festivals and other similar social events.  Originally designed for Burning Man 2019</p>

      <a href='https://github.com/glitch003/CatsfishLEDs'>Source Code Link</a>
      <br />
      <p>This is an arduino project designed for the Particle Xenon board which is a nrf52840 chipset.  You should flash the bootloader on the Particle Xenon to the adafruit nrf52 bootloader.</p>
      <br /><br />
      <Grid container spacing={3}>
        <Grid item>
          <img src='/images/projects/fishfinder/VID_20190808_193417.gif' />
        </Grid>
        <Grid item>
          <img src='/images/projects/fishfinder/VID_20190808_184414.gif' />
        </Grid>
        <Grid item>
          <img src='/images/projects/fishfinder/IMG_20190727_120121.jpg' />
        </Grid>
        <Grid item>
          <img src='/images/projects/fishfinder/IMG_20190809_001510.jpg' />
        </Grid>
        <Grid item>
          <img src='/images/projects/fishfinder/IMG_20190829_152909.jpg' />
        </Grid>

      </Grid>

    </>
  )
}
