import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

export default class Home extends React.Component {
  render () {
    return (
      <div>
        <h1>Greetings, fellow human</h1>

        <p>
          I'm Chris.  Welcome to my humble website.  Here, you can find information about my art and various projects I've done.
        </p>
        <br />
        <RouterLink to='/projects/stylegan/propaganda'>
          <img
            src='/images/projects/stylegan/propaganda/example_1.png'
            style={{
              width: '100%',
              cursor: 'pointer'
            }}
          />
        </RouterLink>

      </div>
    )
  }
}
