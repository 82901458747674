import React from "react";
// import Img from 'react-fix-image-orientation'

const StyledImage = (props) => (
  <img
    {...props}
    style={{
      width: "50%",
    }}
  />
);

export default function ArtistBuyingOwnNFT(props) {
  return (
    <>
      <h1>An artist buys their own NFT of themself buying their own NFT</h1>

      <p>
        Due to the anonymity provided by cryptocurrency addresses, artists have
        been known to buy their own NFTs. This can set a "price floor" for a
        specific artist's art.{" "}
      </p>

      <p>
        This piece is a video of me minting and buying my own NFT of myself
        minting and buying my own NFT.
      </p>

      <p>
        You can see it{" "}
        <a href="https://teia.art/objkt/28841">here on hicetnunc</a>, a
        artist-driven green NFT platform, or watch it in a frame below
      </p>
      <br />
      {/* <video width='100%' height='auto' loop controls autoplay muted> */}
      {/*   <source src='https://chriscassano-dev.s3-us-west-1.amazonaws.com/output.mp4' type='video/mp4' /> */}
      {/*   Your browser does not support the video tag. */}
      {/* </video> */}
      <iframe
        style={{ width: "100%", height: "80vh" }}
        sandbox="allow-scripts allow-same-origin allow-popups"
        src="https://teia.art/objkt/28841"
      />
    </>
  );
}
