import React from 'react'
import { Link } from 'react-router-dom'

export default class Microgrants extends React.Component {
  render () {
    return (
      <div>
        <h1>Mission Microgrants</h1>

        <p>We know that artists are leaving San Francisco because the rent and cost of living is too high.  So let's do something about it.</p>

        <p>We created the Mission Microgrants program: A yearly microgrant for artists living in SF to help them pay for rent.</p>

        <p>We are proud to announce our first grant of $4,800 will be awarded soon!</p>

        <p>Looking to apply for the next grant?  Check back here in a few months for more info.</p>

        <p>Think this is cool and want to help?  Contact me using the "Contact" on the menu.  Mission Microgrants is funded by generous donors like you.</p>
      </div>
    )
  }
}
