import React from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}))

export default function Contact (props) {
  const classes = useStyles()

  return (
    <div>
      <h1>Contact Chris</h1>
      <br />
      <br />

      <div>
        <form name='contact' method='POST' netlify-honeypot='bot-field' data-netlify='true'>
          <input type='hidden' name='form-name' value='contact' />
          <p style={{ display: 'none' }}>
            <label>Don’t fill this out if you're human: <input name='bot-field' /></label>
          </p>

          <TextField
            type='email'
            name='email'
            label='Your email address'
            placeholder='name@example.com'
            fullWidth
            className={classes.textField}
          />

          <TextField
            id='outlined-multiline-static'
            label='Message'
            multiline
            rows='4'
            name='message'
            fullWidth
            placeholder='Hello, Chris'
            className={classes.textField}
            margin='normal'
            variant='outlined'
          />

          <Button variant='contained' color='primary' type='submit'>
            Send
          </Button>
        </form>
      </div>

    </div>
  )
}
