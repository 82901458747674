import React from 'react'
// import Img from 'react-fix-image-orientation'

const StyledImage = (props) =>
  <img
    {...props}
    style={{
      width: '50%'
    }}
  />

export default function NftClub (props) {
  return (
    <>
      <h1>Create links that only NFT holders can view</h1>

      <p>Create links that require a signature from a wallet that contains an NFT to view.  Works with Ethereum.  See it in action here: <a href='https://nftclub.in'>nftclub.in</a></p>

    </>
  )
}
